<template>
  <div class="parameters">
    <PageTitle title="參數設置" hideBtn style="margin-bottom: 64px" />
    <div class="parameters-category-list">
      <CategoryBlock
        v-for="param in paramsRoutes"
        :key="param.name"

        :data="param"
      />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle'
import CategoryBlock from './components/CategoryBlock'
import { mapGetters } from 'vuex'
import { filter } from 'lodash'

export default {
  name: 'Parameters',
  components: {
    CategoryBlock,
    PageTitle,
  },
  computed: {
    ...mapGetters([
      'asyncRoutes',
    ]),
    paramsRoutes () {
      if (this.asyncRoutes) {
        if (!this.asyncRoutes.length) return []
      }
      const routes = this.asyncRoutes.filter(route => route.name === 'Parameters')
      if (routes) {
        if (!routes.length) return []
      }
      const children = routes[0].children
      return filter(children, route => !route.hidden)
    },
  },
  data: () => ({

  }),
}
</script>

<style lang="postcss" scoped>
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}
</style>
